import { useForm } from "@mantine/form"
import Accounts from "@vesatogo/grass-assets/graphics/accounts.png"
import Farmer from "@vesatogo/grass-assets/graphics/farmer.png"
import Trading from "@vesatogo/grass-assets/graphics/trading.png"
import "@vesatogo/grass-carousel/index.css"
import { Toast } from "@vesatogo/grass-core"
import { GrassLogin } from "@vesatogo/grass-login"
import "@vesatogo/grass-login/index.css"
import "@vesatogo/grass-table/index.css"
import { useEffect } from "react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import BizLogo from "~/assets/logo.svg"
import {
  useAgentLoginMutation,
  useAuthenticatedUserQuery,
} from "~/generated/graphql"
import { useURQLClient } from "~/services/urql.service"
const Logo = () => <img alt="biz logo" width={48} height={48} src={BizLogo} />
const LoginPage = () => {
  const navigate = useNavigate()
  const { resetClient } = useURQLClient()
  const { getInputProps, values } = useForm({
    initialValues: {
      username: "",
      password: "",
    },
  })
  const [{ data }] = useAuthenticatedUserQuery()

  useEffect(() => {
    if (data?.me?.id) {
      navigate("/d")
    }
  }, [data])
  const [, login] = useAgentLoginMutation()
  const slides = [
    {
      id: 1,
      title: "Farmer",
      subtitle: "Manager the supplier directory",
      renderImage: style => {
        return (
          <img
            className={style}
            src={Farmer}
            alt="Farmer"
            width={500}
            height={500}
          />
        )
      },
    },
    {
      id: 2,
      title: "Trading",
      subtitle:
        "Enable easy procurement process and keep comfortable records of transactions.",
      renderImage: style => {
        return (
          <img
            className={style}
            src={Trading}
            alt="Farmer"
            width={500}
            height={500}
          />
        )
      },
    },
    {
      id: 3,
      title: "Accounts",
      subtitle: "Check expenses in processes and take actions accordingly.",
      renderImage: style => {
        return (
          <img
            className={style}
            src={Accounts}
            alt="Farmer"
            width={500}
            height={500}
          />
        )
      },
    },
  ]
  return (
    <GrassLogin
      brand={{
        logo: Logo,
        displayName: "Indo Fruits Development Council",
        legalName: "Indo Fruits Development Council",
        phoneNumber: "",
        email: "",
      }}
      slides={slides}
      usernameProps={{
        inputProps: {
          ...getInputProps("username"),
          placeholder: "Employee ID",
        },
      }}
      passwordProps={{
        inputProps: getInputProps("password"),
      }}
      onSubmit={async () => {
        try {
          const { data, error } = await login(values)
          if (error || !data?.login) {
            return toast.custom(
              <Toast
                title={"Unable to login, please enter valid credentials"}
                intent="danger"
              />
            )
          }
          const auth = data?.login
          toast.custom(<Toast title={`Logged in as ${auth.me?.full_name}`} />)
          localStorage.setItem("token", auth.token)
          localStorage.removeItem("department")
          resetClient()
          navigate("/d")
        } catch (error) {
          console.error(error)
        }
      }}
    />
  )
}

export default LoginPage
